import React, { Component } from 'react';
import CareerSelector from '../../components/career-selector/career_selector';
import ModalInfo from '../modal-info/modal-info';
import ModalTerms from '../modal-terms/modal-terms';
import AppService from '../../services/app-service';
import { Helmet } from 'react-helmet'
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faCopyright } from '@fortawesome/free-solid-svg-icons';

import "./detail-career.css";

class DetailCareer extends Component {

    state = {
        carrera: []
    };

    async componentDidMount() {
        try {
            const nombre = this.props.match.params.nombre;
            const carrera = await AppService.getDetailByName(nombre);
            //document.title = "Saturación de mercado de " + carrera[0].texto_nombre_carrera;
            this.setState({ carrera });
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        const { carrera } = this.state;
        return (
            <div className="page-career">
                <Container>
                    {
                        carrera.map((item, key) => {
                            return (
                                <div key={key}>
                                    <Helmet>
                                        <title>Saturación de mercado de {item.texto_nombre_carrera}</title>
                                        <meta name="description" content={`Revisa el índice de saturación de ${item.texto_nombre_carrera} y toma la mejor decisión sobre tu futuro laboral`} />
                                    </Helmet>
                                    <Row className="vh-20 py-3 mobile-height align-items-center">
                                        <Col className="text-center">
                                            <Link id="volverHome" to="/" className="backBtn position-absolute ml-md-2 ml-4" >
                                                <FontAwesomeIcon className="mr-4" icon={faChevronLeft} />
                                                <span className="d-none d-sm-inline">Atrás</span>
                                            </Link>
                                            <p className="title_txt">Análisis de carrera</p>
                                            <h1 className="title_txt_nombre_carrera">{item.texto_nombre_carrera}</h1>
                                        </Col>
                                    </Row>
                                    <div className="col-md-6 offset-md-3 careerSelectorDiv">
                                        <CareerSelector></CareerSelector>
                                    </div>
                                    <Row className="containerDescp py-4 vh-50 align-items-center">
                                        <Col className="d-flex align-items-end flex-md-row flex-column">
                                            <Col md={{ size: 3, order: 1 }} sm xs={{ order: 2 }} className="align-items-center text-center mb-4">
                                                <h3 className="txt_rank">Competencia</h3>
                                                <h5>
                                                    <span className={'badge badge-pill ' + item.color_tramo_competitividad}>{item.texto_nivel_competividad}</span>
                                                </h5>
                                                <p className="txt-total">{item.descripcion_competencia}</p>
                                            </Col>
                                            <Col md={{ size: 6, order: 2 }} xs={{ order: 1 }} className="align-items-center text-center mb-4">
                                                <h2 className={'rnkSaturacion ' + item.escala_saturacion}>{item.texto_escala_saturacion.toUpperCase()}</h2>
                                                <span className={'txtSaturacion ' + item.escala_saturacion}>Saturación {item.indice_saturacion}</span>
                                                <br></br>
                                                <img className="img-fluid my-4 emoticonsEscala" alt="logo" src={process.env.PUBLIC_URL + '/img/escala-saturacion/' + item.escala_saturacion.toLowerCase() + '.png'} />
                                            </Col>
                                            <Col md={{ size: 3, order: 3 }} sm xs={{ order: 3 }} className="align-items-center text-center mb-md-4">
                                                <h3 className="txt_rank">Demanda</h3>
                                                <h5>
                                                    <span className={'badge badge-pill ' + item.color_tramo_demanda}>{item.texto_nivel_demanda}</span>
                                                </h5>
                                                <p className="txt-total">{item.descripcion_demanda}</p>
                                            </Col>
                                        </Col>
                                    </Row>
                                    
                                    <Row className="vh-20 align-items-center">
                                        <Col className="align-items-center text-center">
                                            <p className="txt_description py-3">{item.descripcion_saturacion}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div className="col-sm-12 text-center modalInfoSection">
                                            <ModalInfo></ModalInfo>
                                        </div>
                                    </Row>
                                    
                                </div>
                            )
                        })
                    }
                </Container>
                <div className="container-fluid">
                    <Row className="vh-15 ">
                        <Col className="footer">
                            <Row>
                                <div className="col-sm-12 text-center legalTermsSection">
                                    <ModalTerms></ModalTerms>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-sm-12 footerSection">
                                    <p className="mx-auto mb-0">Copyright <FontAwesomeIcon icon={faCopyright} /> 1999 - 2020 <a className="footer-url" href="https://www.trabajando.cl">Trabajando.com</a><br className="br-desk"></br> todos los derechos reservados </p>
                                </div>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div >
        );
    }

}

export default DetailCareer;

