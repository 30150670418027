export var carreras = [{
  id: '40',
  nombre: 'Ingeniería Comercial'
},
{
  id: '39',
  nombre: 'Ingeniería Civil'
},
{
  id: '354',
  nombre: 'Ingeniería Civil Industrial'
},
{
  id: '439',
  nombre: 'Técnico en Mecánica Automotriz'
},
{
  id: '2',
  nombre: 'Administración de Empresas'
},
{
  id: '23',
  nombre: 'Contador Auditor'
},
{
  id: '473',
  nombre: 'Ingeniería en Administración de Empresas'
},
{
  id: '231',
  nombre: 'Ingeniería en Prevención de Riesgos'
},
{
  id: '334',
  nombre: 'Ingeniería Civil en Informática'
},
{
  id: '450',
  nombre: 'Técnico de Nivel Superior en Administración de Empresas'
},
{
  id: '22',
  nombre: 'Construcción Civil'
},
{
  id: '64',
  nombre: 'Técnico en Turismo y Hotelería'
},
{
  id: '238',
  nombre: 'Técnico en Enfermería'
},
{
  id: '122',
  nombre: 'Ingeniería Civil Eléctrica'
},
{
  id: '104',
  nombre: 'Ingeniería en Computación e Informática'
},
{
  id: '81',
  nombre: 'Gastronomía y Cocina Internacional'
},
{
  id: '54',
  nombre: 'Psicología'
},
{
  id: '548',
  nombre: 'Técnico en Mantenimiento Mecánico'
},
{
  id: '123',
  nombre: 'Ingeniería en Construcción'
},
{
  id: '312',
  nombre: 'Ingeniería Civil Mecánica'
},
{
  id: '511',
  nombre: 'Administración Gastronómica'
},
{
  id: '273',
  nombre: 'Electromecánica'
},
{
  id: '148',
  nombre: 'Ingeniería Mecánica'
},
{
  id: '204',
  nombre: 'Técnico en Electricidad y Electricidad Industrial'
},
{
  id: '192',
  nombre: 'Ingeniería en Logística'
},
{
  id: '99',
  nombre: 'Contabilidad General'
},
{
  id: '207',
  nombre: 'Técnico en Logística'
},
{
  id: '128',
  nombre: 'Ingeniería Industrial'
},
{
  id: '182',
  nombre: 'Ingeniería Civil Electrónica'
},
{
  id: '360',
  nombre: 'Técnico en Farmacia'
},
{
  id: '38',
  nombre: 'Técnico Universitario en Informática'
},
{
  id: '31',
  nombre: 'Educación Parvularia'
},
{
  id: '342',
  nombre: 'Mantenimiento Industrial'
},
{
  id: '333',
  nombre: 'Ingeniería civil electricista'
},
{
  id: '58',
  nombre: 'Química y Farmacia'
},
{
  id: '315',
  nombre: 'Administración de Hotelera'
},
{
  id: '69',
  nombre: 'Ingeniería en Electricidad'
},
{
  id: '249',
  nombre: 'Mecánica Industrial'
},
{
  id: '358',
  nombre: 'Ingeniería en Maquinaría y Vehiculos pesados'
},
{
  id: '32',
  nombre: 'Enfermería'
},
{
  id: '193',
  nombre: 'Ingeniería en Mantenimiento Industrial'
},
{
  id: '366',
  nombre: 'Técnico en Administración de Empresas Mención Recursos Humanos'
},
{
  id: '261',
  nombre: 'Ingeniería de Ejecución en Mecánica Automotriz y Autotrónica'
},
{
  id: '536',
  nombre: 'Ingeniería en Ejecución Mecánica'
},
{
  id: '314',
  nombre: 'Administración Turística y Hotelera'
},
{
  id: '63',
  nombre: 'Trabajo Social'
},
{
  id: '88',
  nombre: 'Ingeniería en Mecánica Automotriz'
},
{
  id: '186',
  nombre: 'Técnico en Instrumentación, Automatización y Control Industrial'
},
{
  id: '518',
  nombre: 'Ingeniería en Construcción Civil'
},
{
  id: '96',
  nombre: 'Ingeniería de Ejecución en Administración'
},
{
  id: '125',
  nombre: 'Ingeniería en Obras Civiles'
},
{
  id: '6',
  nombre: 'Técnico en programación y analisis de sistemas'
},
{
  id: '52',
  nombre: 'Periodismo'
},
{
  id: '118',
  nombre: 'Técnico en Electrónica y Electrónica Industrial'
},
{
  id: '124',
  nombre: 'Ingeniería en Minas'
},
{
  id: '143',
  nombre: 'Telecomunicaciones'
},
{
  id: '552',
  nombre: 'Ingeniería en Recursos Humanos'
},
{
  id: '545',
  nombre: 'Ingeniería Civil Química'
},
{
  id: '19',
  nombre: 'Técnico en Computación e Informática'
},
{
  id: '477',
  nombre: 'Técnico en Prevención de Riesgos'
},
{
  id: '190',
  nombre: 'Ingeniería Electrónica'
},
{
  id: '446',
  nombre: 'Ingeniería Ejecución Administración de Empresas'
},
{
  id: '427',
  nombre: 'Técnico Financiero'
},
{
  id: '357',
  nombre: 'Ingeniería de Ejecución Industrial'
},
{
  id: '516',
  nombre: 'Ingeniería en Ejecución Electrónica'
},
{
  id: '109',
  nombre: 'Ingeniería Ambiental'
},
{
  id: '355',
  nombre: 'Ingeniería Civil Matemática'
},
{
  id: '253',
  nombre: 'Administración Financiera'
},
{
  id: '4',
  nombre: 'Administración Pública'
},
{
  id: '25',
  nombre: 'Derecho'
},
{
  id: '396',
  nombre: 'Ingeniería en Control de Gestión'
},
{
  id: '8',
  nombre: 'Arquitectura'
},
{
  id: '126',
  nombre: 'Ingeniería en Telecomunicaciones, Conectividad y Redes'
},
{
  id: '263',
  nombre: 'Técnico Agrícola'
},
{
  id: '478',
  nombre: 'Contador Público y Auditor'
},
{
  id: '133',
  nombre: 'Ingeniería en Química'
},
{
  id: '27',
  nombre: 'Diseño Gráfico'
},
{
  id: '116',
  nombre: 'Pedagogía en Educación Básica'
},
{
  id: '335',
  nombre: 'Ingeniería en Automatización y Control Industrial'
},
{
  id: '307',
  nombre: 'Publicidad'
},
{
  id: '61',
  nombre: 'Sociología'
},
{
  id: '340',
  nombre: 'Ingeniería en Comercio Internacional'
},
{
  id: '18',
  nombre: 'Comercio Exterior'
},
{
  id: '500',
  nombre: 'Control de Gestión'
},
{
  id: '286',
  nombre: 'Pedagogía en Educación Diferencial'
},
{
  id: '157',
  nombre: 'Ingeniería en Conectividad y Redes'
},
{
  id: '554',
  nombre: 'Ingeniería civil ambiental'
},
{
  id: '413',
  nombre: 'Ingeniería Ejecución en Gestión Industrial'
},
{
  id: '46',
  nombre: 'Medicina'
},
{
  id: '130',
  nombre: 'Ingeniería Civil Metalúrgica'
},
{
  id: '369',
  nombre: 'Técnico en Mantenimiento Industrial'
},
{
  id: '199',
  nombre: 'Técnico en Construcción y Obras Civiles'
},
{
  id: '486',
  nombre: 'Ingeniería Agrícola'
},
{
  id: '285',
  nombre: 'Técnico en Minería'
},
{
  id: '5',
  nombre: 'Agronomía'
},
{
  id: '42',
  nombre: 'Kinesiología'
},
{
  id: '288',
  nombre: 'Ingeniería en Información y Control de Gestión'
},
{
  id: '135',
  nombre: 'Técnico en Marketing'
},
{
  id: '57',
  nombre: 'Técnico en Análisis Químico'
},
{
  id: '97',
  nombre: 'Ingeniería en Alimentos'
},
{
  id: '260',
  nombre: 'Ingeniería en Marketing'
},
{
  id: '173',
  nombre: 'Diseño'
},
{
  id: '271',
  nombre: 'Técnico en Diseño Gráfico'
},
{
  id: '461',
  nombre: 'Técnico en Trabajo Social'
},
{
  id: '70',
  nombre: 'Secretariado Ejecutivo'
},
{
  id: '47',
  nombre: 'Medicina Veterinaria'
},
{
  id: '28',
  nombre: 'Diseño Industrial'
},
{
  id: '35',
  nombre: 'Ingeniería en Comercio Exterior'
},
{
  id: '49',
  nombre: 'Nutrición y Dietética'
},
{
  id: '156',
  nombre: 'Manteniemiento de Maquinaria Pesada'
},
{
  id: '526',
  nombre: 'Ingeniería Civil Telemática'
},
{
  id: '60',
  nombre: 'Relaciones Públicas'
},
{
  id: '365',
  nombre: 'Técnico en alimentos'
},
{
  id: '301',
  nombre: 'Pedagogía en Lenguaje y Comunicación'
},
{
  id: '72',
  nombre: 'Ingeniería en Geomensura y Cartografía'
},
{
  id: '276',
  nombre: 'Ingeniería en Administración Industrial'
},
{
  id: '53',
  nombre: 'Técnico en Programación Computacional'
},
{
  id: '535',
  nombre: 'Química'
},
{
  id: '451',
  nombre: 'Técnico de Nivel Superior en Computación e Informática'
},
{
  id: '381',
  nombre: 'Tecnologías de la Información y Comunicación'
},
{
  id: '275',
  nombre: 'Ingeniería en Gestión de Operaciones Logísticas'
},
{
  id: '226',
  nombre: 'Topografía'
},
{
  id: '283',
  nombre: 'Química Industrial'
},
{
  id: '218',
  nombre: 'Técnico en Refrigeración'
},
{
  id: '102',
  nombre: 'Estadística'
},
{
  id: '185',
  nombre: 'Asistente Ejecutivo'
},
{
  id: '20',
  nombre: 'Comunicación Audiovisual  y Multimedia'
},
{
  id: '121',
  nombre: 'Ingeniería en Logística y Transporte'
},
{
  id: '71',
  nombre: 'Secretariado Ejecutivo Bilingue'
},
{
  id: '245',
  nombre: 'Técnico Jurídico'
},
{
  id: '89',
  nombre: 'Ingeniería en Acuicultura'
},
]