import { Component } from 'react';
import { carreras } from '../db/carreras.js'
import { detalleCarreras } from "../db/detalle_carreras.js";
import { descripcionSaturacion } from "../db/descripcion_saturacion.js";

const utils = require('../libs/utils');
var detalle = [];
var descripcion = [];
var nombreDescripcionSaturacion;
class AppService extends Component {

    constructor(props) {
        super(props);
        this.getSaturationFromDetail = this.getSaturationFromDetail.bind(this);
        this.getSaturationDescription = this.getSaturationDescription.bind(this);
    }
    async getAllCarrers() {
        return carreras;
    }

    async getDetail(id) {
        Object.keys(detalleCarreras).forEach(function (key) {
            if (detalleCarreras[key].id_carrera === id) {
                detalle = detalleCarreras[key].detalle_carrera;
            }
        });
        // let nombreDescripcionSaturacion = this.getSaturationFromDetail(detalle);
        // console.log(nombreDescripcionSaturacion);
        // let descripcionSaturacion = this.getSaturationDescription(nombreDescripcionSaturacion);
        // detalle[0].descripcion_saturacion = descripcionSaturacion;
        return detalle;
    }

    async getDetailByName(nombre) {
        Object.keys(detalleCarreras).forEach(function (key) {
            if (nombre === utils.formatearTexto(detalleCarreras[key].detalle_carrera[0].texto_nombre_carrera)) {
                detalle = detalleCarreras[key].detalle_carrera;
            }
        });
         return detalle;
    }

    getSaturationFromDetail(detalle) {
        Object.keys(detalle).forEach(function (key) {
            nombreDescripcionSaturacion = detalle[key].descripcion_saturacion;
        });
        return nombreDescripcionSaturacion;
    }

    getSaturationDescription(nombreDescripcionSaturacion) {
        Object.keys(descripcionSaturacion).forEach(function (key) {
            if (descripcionSaturacion[key].id_descripcion_saturacion === nombreDescripcionSaturacion) {
                descripcion = descripcionSaturacion[key].detalle_descripcion_saturacion[0].detalle;
            }
        });
        return descripcion;
    }
}

export default new AppService();