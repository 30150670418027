import React, { Component } from 'react';
//import AppService from '../../services/app-service';
import { Container, Row, Col } from 'reactstrap';
import CareerSelector from '../../components/career-selector/career_selector';
import { Helmet } from 'react-helmet'
import ModalTerms from '../modal-terms/modal-terms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopyright } from '@fortawesome/free-solid-svg-icons';

import './home.css'

class Home extends Component {
    render() {
        return (
            <div className="page-home">
                <Helmet>
                    <title>Elige tu carrera en base a su saturación de mercado - Tucarrera.cl</title>
                    <meta name="description" content="Te ayudamos a escoger la carrera que vas a estudiar compartiendo contigo nuestro índice de saturación de carreras" />
                </Helmet>
                <Container className="vh-100">
                    <Row className="align-items-center vh-100">
                        <Col sm="12" md="6" xs="12">
                            <img width="200" className="imgLogo mb-5 align-self-sm-start d-flex align-self-center mx-auto ml-md-0" alt="logo" src={process.env.PUBLIC_URL.toLowerCase() + '/img/logo.png'} />
                            <div className="d-flex flex-column justify-content-between container-mobile mx-auto ">

                                <h1 className="title_elige mb-5"><strong>¿Qué</strong> vas a<br className="br-desk"></br> <strong>estudiar?</strong></h1>
                                <p className="txt-msj"><strong>Elegir una carrera</strong> es una decisión que definirá tu vida laboral. Selecciona una carrera <strong>para que conozcas sus atributos</strong> y estés informado.</p>
                                <CareerSelector></CareerSelector>
                            </div>
                        </Col>
                        <Col className="d-none d-sm-block" sm="12" md="6" xs="12">
                            <img className="img-fluid" alt="fondo" src={process.env.PUBLIC_URL + '/img/bgeligetc.png'} />
                        </Col>
                    </Row>
                </Container>
                <div className="container-fluid">
                    <Row className="vh-15 ">
                        <Col className="footer">
                            <Row>
                                <div className="col-sm-12 text-center legalTermsSection">
                                    <ModalTerms></ModalTerms>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-sm-12 footerSection">
                                    <p className="mx-auto mb-0">Copyright <FontAwesomeIcon icon={faCopyright} /> 1999 - {(new Date().getFullYear())} <a className="footer-url" href="https://www.trabajando.cl">Trabajando.com</a><br className="br-desk"></br> todos los derechos reservados </p>
                                </div>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default Home;

