import React, { Component } from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import Home from '../components/home/home';
import DetailCareer from '../components/detail-career/detail-career';
import NotFound from '../components/not-found/not-found';

class RoutesApp extends Component {

    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route path="/detalle/:id" component={DetailCareer} />
                    <Route path="/:nombre" component={DetailCareer} />
                    <Route component={NotFound} />
                </Switch>
            </BrowserRouter>
        );
    }

}

export default RoutesApp;