import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, Row } from 'reactstrap';

const ModalInfo = (props) => {
    const {
        className = "modalInfo"
    } = props;

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    return (
        <div>
            <Button color="link" className="modalInfoBtn" onClick={toggle}>
                Conoce más de las escalas del sitio y de cómo se calculan los índices.
        </Button>
            <Modal isOpen={modal} toggle={toggle} className={className}>
                <ModalHeader toggle={toggle}>Detalle de las escalas</ModalHeader>
                <ModalBody>
                    <h3>¿En qué se basan para entregar la recomendación y qué significan las escalas del sitio?</h3>
                    <h4>La recomendación</h4>
                    <p>
                        La recomendación se basa en la combinación de tres factores: <b>demanda</b>, <b>competencia</b>, y <b>saturación</b>.
                </p>
                    <p>
                        Estos datos los obtenemos de los datos de ofertas de empleo y postulaciones de usuarios de la red Trabajando.com en lo que va del año 2020
                    </p>
                    <h4>Las caritas de las recomendaciones</h4>
                    <Row className="recomendationLevel">
                        <div className="col col1 text-center level5">
                            <img className="img-fluid my-4 emoticonsEscala" alt="logo" src={process.env.PUBLIC_URL + '/img/escala-saturacion/muy_saturada.png'} />
                        </div>
                        <div className="col col2 text-center level4">
                            <img className="img-fluid my-4 emoticonsEscala" alt="logo" src={process.env.PUBLIC_URL + '/img/escala-saturacion/saturada.png'} />
                        </div>
                        <div className="col col3 text-center level3">
                            <img className="img-fluid my-4 emoticonsEscala" alt="logo" src={process.env.PUBLIC_URL + '/img/escala-saturacion/indiferente.png'} />
                        </div>
                        <div className="col col4 text-center level2">
                            <img className="img-fluid my-4 emoticonsEscala" alt="logo" src={process.env.PUBLIC_URL + '/img/escala-saturacion/poco_saturada.png'} />
                        </div>
                        <div className="col col5 text-center level1">
                            <img className="img-fluid my-4 emoticonsEscala" alt="logo" src={process.env.PUBLIC_URL + '/img/escala-saturacion/recomendada.png'} />
                        </div>
                    </Row>

                    <Row className="recomendationLevelText">
                        <div className="col">
                            Saturada
                    </div>
                        <div className="col text-right">
                            Recomendada
                    </div>
                    </Row>
                    <p>
                        Cada recomendación viene acompañada por emoticones para facilitar su lectura. La escala va desde <b>Saturada</b> hasta <b>Buena elección</b>, pasando por otros tres niveles intermedios que te ayudarán a seleccionar la mejor opción para tu futuro.
                </p>

                    <h4>Los indicadores</h4>
                    <h5>Saturación</h5>
                    <p>
                        A la relación entre cantidad de ofertas de empleo que incluyen a una carrera como requisito y la cantidad de postulantes interesados en esa oferta le llamamos <b>índice de saturación</b>, el que expresamos en <b>porcentaje</b>.
                </p>
                    <div className="col text-center SATURADA saturationSection">
                        <div className="saturationLevel">41%</div>
                        <div className="saturationText">Saturación</div>
                    </div>

                    <h5>Índice de Demanda</h5>
                    <p>
                        <b>A menor ranking</b> la carrera se encuentra dentro de las <b>más solicitadas</b> por las empresas en Trabajando.com, es decir, con un mayor campo laboral. Cada tramo del ranking es expresado en la forma de 5 conceptos: Muy alta, Alta, Promedio, Baja, Muy baja. Los tramos son variables y están en función de sus indicadores, no de la saturación general.
                </p>
                    <div className="col text-center demandSection">
                        <div className="demandTitle">Índice de Demanda</div>
                        <div className="demandLevel RECOMENDADA">Muy alta</div>
                    </div>
                    <p className="pMargen">Cada tramo es apoyado por un texto, donde se entrega el número exacto que la carrera ocupa dentro del ranking, para que te hagas una idea de <b>qué tan demandada es</b>. Ejemplo: Esta carrera tiene un índice de competencia 74 de 100, lo que indica que su competencia es Promedio. Una carrera con un índice '1' es una carrera con muy baja competencia.</p>
                    <h5>Índice de Competencia</h5>
                    <p>
                        <b>A menor ranking</b> la carrera presenta <b>menor competencia</b> entre quienes postulan en la red Trabajando.com, es decir, aumentan tus posibilidades de ser seleccionado para un puesto en contraste con aquellas carreras donde la competencia es igual o supera la media. Cada tramo del ranking es expresado en la forma de 5 conceptos: Muy alta, Alta, Promedio, Baja, Muy baja. Los tramos son variables y están en función de sus indicadores, no de la saturación general.
                    </p>
                    <div className="col text-center demandSection">
                        <div className="demandTitle">Índice de Competencia</div>
                        <div className="demandLevel MUY_SATURADA">Muy bajo</div>
                    </div>
                    <p>Cada tramo es apoyado por un texto, donde se entrega el número exacto que la carrera ocupa dentro del ranking, para que te hagas una idea de <b>qué tanta competencia tiene</b>. Ejemplo: Esta carrera tiene un índice de competencia 8 de 100, lo que indica que su competencia es Muy baja. Una carrera con un índice '1' es una carrera con muy baja competencia.</p>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default ModalInfo;