export var descripcionSaturacion = [
    {
        id_descripcion_saturacion: "CARRERA_IDEAL",
        detalle_descripcion_saturacion: [{
            detalle: "Carrera Ideal, mucho campo laboral y poca disponibilidad de profesionales para el mercado."
        }]
    },
    {
        id_descripcion_saturacion: "BUENA_CARRERA",
        detalle_descripcion_saturacion: [{
            detalle: "Buena Carrera, ya que tiene un campo laboral amplio y número de profesionales adecuado para cubrir la demanda, en este segmento encontrarás carreras muy interesante con niveles de saturación medios y bajos."
        }]
    },
    {
        id_descripcion_saturacion: "CARRERA_RIESGOSA",
        detalle_descripcion_saturacion: [{
            detalle: "Carrera Riesgosa, ya que si bien el campo laboral es amplio tiene una gran cantidad de profesionales aptos para cubrir las vancantes, en este segmento te encontrarás con carreras de alta y baja saturación, ¡elije bien!."
        }]
    },
    {
        id_descripcion_saturacion: "CARRERA_INTERESANTE",
        detalle_descripcion_saturacion: [{
            detalle: "Carrera Interesante, ya que posee un campo laboral atractivo y pocos profesionales capaces de cubrir las vacantes, en este segmento te encontrarás con interesantes carreras con niveles de saturación bajos y probablemente emergentes."
        }]
    },
    {
        id_descripcion_saturacion: "CARRERA_DE_NICHO",
        detalle_descripcion_saturacion: [{
            detalle: "Carrera de Nicho, tiene un campo laboral medio y una cantidad de profesionales adecuados a las necesidades. La dispersión en la Saturación de este segmento es amplia, pero en general son carreras estables donde puedes encontrar interesantes oportunidades."
        }]
    },
    {
        id_descripcion_saturacion: "CARRERA_RIESGOSA_2",
        detalle_descripcion_saturacion: [{
            detalle: "Carrera Riesgosa, ya que si bien el campo laboral medio, pero cantidad de profesionales que exceden las necesidades de mercado. Te encontrarás con carreras con niveles de saturación alta, salvo un par de excepciones. Debes elegir muy bien!."
        }]
    },
    {
        id_descripcion_saturacion: "CARRERA_EMERGENTE",
        detalle_descripcion_saturacion: [{
            detalle: "Carrera Emergente, campo laboral reducido, pero existen pocos candidatos, lo que puede transformarse en una oportunidad, aunque puede ser riesgosa. Ojo con tu elección."
        }]
    },
    {
        id_descripcion_saturacion: "CARRERA_DE_NICHO_2",
        detalle_descripcion_saturacion: [{
            detalle: "Carrera de Nicho, tiene un campo estrecho y una cantidad de profesionales adecuados a las necesidades, por lo cual puede ser una oportunidad, pero a su vez pueden ser muy riesgosa."
        }]
    },
    {
        id_descripcion_saturacion: "CARRERA_BAJO_CAMPO_LABORAL",
        detalle_descripcion_saturacion: [{
            detalle: "Carrera con con bajo campo laboral y alto número de postulantes, segmento más riesgoso."
        }]
    },
]