import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';

const ModalTerms = (props) => {
  const {
    className = "modalTerms"
  } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <div>
        <Button color="link" className="modalTermsBtn" onClick={toggle}>
        Términos de uso del sitio tucarrera.cl
        </Button>
        <Modal isOpen={modal} toggle={toggle} className={className}>
            <ModalHeader toggle={toggle}>Términos de uso</ModalHeader>
            <ModalBody>
            La información contenida en el presente estudio refleja exclusivamente el comportamiento de la oferta y demanda de empleo en Trabajando.com y sus portales asociados, durante el año 2020, y no pretende indicar en ningún momento que representa fielmente el estado del mercado laboral ni de la demanda general por las carreras que aquí se muestran. Por la naturaleza variable de esta información y del mercado laboral chileno las carreras pueden cambiar sus índices en el tiempo, por lo que Trabajando.com sólo entrega una visión actual de la situación de las carreras y no puede asegurar que estas no cambien en el futuro; por lo anterior, se recomienda comparar con información complementaria relevante tal como la acreditación de la institución y de la carrera elegida, su nivel de deserción, arancel, duración real, tasa de empleabilidad, ingresos promedios, etc.
            <br></br><br></br>
            Este estudio se construye a partir de las publicaciones de ofertas de reclutamiento en la Comunidad Laboral de Trabajando.com y las postulaciones recibidas en cada uno de ellos. Muchos de los avisos de reclutamiento contienen específicamente las carreras e instituciones que preferentemente requieren los reclutadores. El estudio en particular considera 100 de las 283 carreras genéricas, para las cuales encontramos información en la Comunidad Laboral de Trabajando.com
            </ModalBody>
        </Modal>
    </div>
  );
}

export default ModalTerms;