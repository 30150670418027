import React, { Component } from 'react';
import AppService from '../../services/app-service';
import { withRouter, Redirect } from 'react-router-dom';
import Select from 'react-select';
import { Form, FormGroup, Row } from 'reactstrap';


const utils = require('../../libs/utils');

const customStyles = {
    menu: base => ({
      ...base,
      margin: 1,
      textAlign: "left",
      // prevent menu to scroll y
      wordWrap: "break-word"
    })
  };

class CareerSelector extends Component {

    state = {
        carreras: [],
        carrerasLinks: []
    };


    async componentDidMount() {
        try {
            const carreras = await AppService.getAllCarrers()            
            carreras.sort(function(a, b) {
                return (a.nombre.toUpperCase() < b.nombre.toUpperCase()) ? -1 : (a.nombre.toUpperCase() > b.nombre.toUpperCase()) ? 1 : 0;
            });
            this.carreras = carreras.map(
                (item, key)=>({value: utils.formatearTexto(item.nombre), label: item.nombre})
            );
            const carrerasLinks = carreras.map(
                (item, key)=>({href: "/"+utils.formatearTexto(item.nombre), text: item.nombre})
            );
            this.setState({ carreras, carrerasLinks });
        } catch (e) {
            console.log(e);
        }
    }


    handleChange = carreraSeleccionada => {
        //this.props.history.push(`/${carreraSeleccionada.value}`);
        window.location.replace(`/${carreraSeleccionada.value}`);
    };


    render() {
        const { carreras } = this.state;
        const { history } = this.props;
        return (
            <div className="mainHome">
            <Form className="form-carrera">
                <FormGroup>                                        
                    <Select
                    className={"inputElige"}
                    placeholder={"Selecciona una carrera"}
                    onChange={this.handleChange}
                    options = {this.carreras}
                    menuShouldScrollIntoView={false}
                    menuPlacement={"auto"}
                    noOptionsMessage= {()=>"Carrera no encontrada"}
                    styles={customStyles}
                    />
                </FormGroup>
            </Form>
            <Row className="d-none">
                {
                    this.state.carrerasLinks.map((item, idx) => {
                        return <div key={idx} className="col-12">
                            <a href={item.href} title={item.text}>{item.text}</a>
                        </div>
                    })
                }
            </Row>
            </div>
        );
    }
}

export default withRouter(CareerSelector);

