const utils = {
    formatearTexto: function(texto) {
        texto = texto.toLowerCase();
        texto = texto.replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '-');
        texto = texto.replace(/-+/g,'-');
        texto = texto.replace(/á/g,'a');
        texto = texto.replace(/é/g,'e');
        texto = texto.replace(/í/g,'i');
        texto = texto.replace(/ó/g,'o');
        texto = texto.replace(/ú/g,'u');
        texto = texto.replace(/ñ/g,'n');
        return texto;
    }
}

module.exports = utils;